<template>
  <div>
    <PatientList
      :patients="patients"
      :totalDocs="totalDocs"
      :page="page"
      :noDataText="noDataText"
      @fetchList="getPatientsList"
      @viewPatientDetail="patientDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PatientList from "../../components/patients/List";

export default {
  components: {
    PatientList,
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      patients: [],
      noDataText: "Loading...",
    };
  },
  methods: {
    ...mapActions("patient", ["fetchAllPatients"]),
    async getPatientsList(dataTableParams) {
      this.$vs.loading();
      this.fetchAllPatients(dataTableParams).then((res) => {
        if (res.data.data.docs.length > 0) {
          this.patients = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total || 0;
          this.page = res.data.data.pagination.page;
          this.currentPage = res.data.data.pagination.page;
        }else {
          this.patients = [];
          this.totalDocs = 0
          this.page = 1
        }
        this.noDataText = "No Patient Available";
        this.$vs.loading.close();
      });
    },
    patientDetail(id) {
      this.$router.push({
        name: "SuperAdminPatientDetail",
        params: { patientId: id },
      });
    },
  },
};
</script>
